<template>
  <b-row>
    <TIMENotificationModal ref="TIMENotificationModal"></TIMENotificationModal>
    <b-colxx>
      <div class="title-and-right-element">
        <PageTitle :title="pageTitle" :identifier="'Company #'+ this.company.companyID"></PageTitle>
        <div v-if="company.isTime" class="TIME-top-right">
          TIME
        </div>
      </div>
      <b-card class="mb-4 mr-8" :class="timeBorderClass">
        <b-form
          @submit.stop.prevent="onValidateFormSubmit"
          class="av-tooltip tooltip-label-right">
          <confirmation ref="Confirmation"></confirmation>
          <h3 class="subtitle">Demographic</h3>
          <b-row>
             <b-colxx lg="3">
              <b-form-group label="Company Legal Name" >
                <b-form-input
                  ref="companylegalname"
                  v-model.trim="company.companyLegalName"
                  :state="$v.company.companyLegalName.$dirty ? !$v.company.companyLegalName.$error : null"
                ></b-form-input>
                <div class="text-danger" v-if="!$v.company.companyLegalName.maxLengthValue">Must be fewer than 75 characters.</div>
                <div class="text-danger" v-if="!$v.company.companyLegalName.required && $v.company.companyLegalName.$dirty">This field is required.</div>
              </b-form-group>
            </b-colxx>
             <b-colxx lg="3">
              <b-form-group label="Company DBA Name">
                <b-form-input
                  v-model.trim="company.dbaCompanyName"
                  :state="$v.company.dbaCompanyName.$dirty ? !$v.company.dbaCompanyName.$error : null"
                  @input="$v.company.dbaCompanyName.$touch"
                ></b-form-input>
                <div class="text-danger" v-if="!$v.company.dbaCompanyName.maxLengthValue">Must be fewer than 75 characters.</div>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Company Description">
                <b-form-input
                  type="text"
                  size="lg"
                  v-model.trim="company.notes"
                />
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Website">
                <b-form-input
                  v-model.trim="company.website"
                  :state="$v.company.website.$dirty ? !$v.company.website.$error : null"
                  @input="$v.company.website.$touch"
                ></b-form-input>
                <div class="text-danger" v-if="!$v.company.website.maxLengthValue">Must be fewer than 255 characters.</div>
                <div class="text-danger" v-if="!$v.company.website.url && $v.company.website.$dirty"> Must be a valid URL.</div>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Sales Member">
                <multiselect
                  v-model="company.salesMemberID"
                  :options="employees"
                  track-by="employeeID"
                  label="displayName"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3" >
              <b-form-group label="Lead Description">
                <multiselect
                    :style="leadDescriptionBorder"
                    v-model="company.leadDescription"
                    :options="leadDescriptions"
                    track-by="leadDescriptionID"
                    label="leadDescription"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx v-if="company.leadDescription" lg="3" style="display:flex;align-items:center;">
               <div :class="closeRatioTagClass">
                  {{ closeRatio }}
                </div>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Business Line(s)">
                <multiselect
                  v-model="company.businessLines"
                  :options="businessLines"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Business Lines..."
                  track-by="id"
                  label="description"
                ></multiselect>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="3" v-if="!companyID">
              <b-form-group label="Affiliate(s)">
                <multiselect
                    v-model="company.affiliates"
                    :options="consultants"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Affiliates..."
                    track-by="affiliateID"
                    label="companyName"
                ></multiselect>
              </b-form-group>
            </b-colxx>
             <b-colxx lg="3">
              <b-form-group label="Can Download Standards?">
                <switches
                  v-model="company.canDownloadStandards"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Active">
                <switches
                  v-model="company.active"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Created Date">
                <p>{{ company.dateCreated }}</p>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Created By">
                <p>{{ company.createdBy }}</p>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Edited Date">
                <p>{{ company.dateLastModified }}</p>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Edited By">
                <p>{{ company.lastModifiedBy }}</p>
              </b-form-group>
            </b-colxx>
          </b-row>
          <h3 class="subtitle section-header">Customer Inquiry</h3>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Program(s) of Interest">
                  <multiselect
                  v-model="company.programsOfInterest"
                  :options="groupedPrograms"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Programs..."
                  group-values="options"
                  group-label="label"
                  track-by="programID"
                  label="dropdownLabel"
                  ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3" >
              <b-form-group label="Lead State">
                <multiselect
                    v-model="company.leadDoingBusinessInState"
                    :options="states"
                    track-by="stateID"
                    label="stateName"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
            <b-form-group label="Number of Locations">
              <b-form-input
                v-model.trim="company.numberofLocations"
                :class="{ 'is-invalid' : $v.company.numberofLocations.$error }"
                @input="$v.company.numberofLocations.$touch"
                />
                <div class="text-danger" v-if="!$v.company.numberofLocations.numeric && $v.company.numberofLocations.$dirty">
                  Must be a number.
                </div>
             </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
            <b-form-group>
            <b-form-checkbox
              v-model="company.ncalPilotParticipant"
              class="mb-2 mr-sm-2 mb-sm-0"
            >NCAL Pilot Participant</b-form-checkbox>
          </b-form-group>
          </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Lead Type">
                <multiselect
                  v-model="company.leadTypeID"
                  :options="leadTypes"
                  track-by="id"
                  label="description"
                ></multiselect>
              </b-form-group>
            </b-colxx>
            <b-colxx v-if="isLeadTypeOtherVisible" lg="3">
              <b-form-group label="Other">
                <b-form-input
                  type="text"
                  v-model="company.leadTypeOther"
                  :class="{ 'is-invalid' : $v.company.leadTypeOther.$error }"
                  @input="$v.company.leadTypeOther.$touch"
                  />
                <div class="text-danger" v-if="!$v.company.leadTypeOther.required && $v.company.leadTypeOther.$dirty">
                  This field is required.
                </div>
                <div class="text-danger" v-if="!$v.company.leadTypeOther.maxLength && $v.company.leadTypeOther.$dirty">
                  Must be fewer than 150 characters.
                </div>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Inquiry Received Date">
                <b-form-input
                  type="date"
                  v-model="inquiryReceivedDate"
                />
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Timeframe">
                <multiselect
                  v-model="company.accreditationCompletedByID"
                  :options="timeframes"
                  track-by="id"
                  label="description"
                ></multiselect>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-col lg="3">
              <b-form-group label="How did you hear about ACHC?">
                <multiselect
                  v-model="company.leadReferralType"
                  :options="leadReferralTypes"
                  :close-on-select="true"
                  :show-labels="false"
                  label="description"
                  track-by="id"
                  placeholder="Select a referral"
                  @input="$v.company.leadReferralType.$touch"></multiselect>
              </b-form-group>
            </b-col>
            <b-col v-if="isLeadReferralTypeOtherVisible" lg="3">
              <b-form-group label="Other">
                <b-form-input
                  type="text"
                  v-model="company.leadReferralTypeOther"
                  :class="{ 'is-invalid' : $v.company.leadReferralTypeOther.$error }"
                  @input="$v.company.leadReferralTypeOther.$touch"/>
                <div v-if=$v.company.leadReferralTypeOther.$error>
                  <div class="text-danger" v-if="!$v.company.leadReferralTypeOther.maxLength">Must be fewer than 150 characters.</div>
                  <div class="text-danger" v-if="!$v.company.leadReferralTypeOther.required">This field is required.</div>
                </div>
              </b-form-group>
            </b-col>
            <b-col v-if="isConsultantVisible" lg="3">
              <b-form-group
                label="Consultant"
              >
                <b-form-select
                  plain
                  v-model="company.inquiryAffiliateID"
                  :options="consultantOptions"
                  @input="$v.company.inquiryAffiliateID.$touch"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="isConsultantsOtherVisible"  lg="3">
              <b-form-group
                label="Other"
              >
                <b-form-input
                  type="text"
                  v-model="company.consultantsOther"
                  :state="$v.company.consultantsOther.$dirty ? !$v.company.consultantsOther.$error : null"
                  @input="$v.company.consultantsOther.$touch"/>
              </b-form-group>
              <div v-if=$v.company.consultantsOther.$error>
                  <div class="text-danger" v-if="!$v.company.consultantsOther.maxLength">Must be fewer than 150 characters.</div>
                  <div class="text-danger" v-if="!$v.company.consultantsOther.required">This field is required.</div>
                </div>
            </b-col>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Affiliated with Hospital or Health System?">
                <switches
                  v-model="company.hospitalAffiliated"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
            <b-colxx md="3">
              <b-form-group v-if="company.hospitalAffiliated" label="Health System Name">
                <b-form-input
                  v-model.trim="company.hospitalAffiliatedName"
                  :state="$v.company.hospitalAffiliatedName.$dirty ? !$v.company.hospitalAffiliatedName.$error : null"
                  @input="$v.company.hospitalAffiliatedName.$touch"
                ></b-form-input>
                <div v-if=$v.company.hospitalAffiliatedName.$error>
                  <div class="text-danger" v-if="!$v.company.hospitalAffiliatedName.maxLengthValue">Must be fewer than 100 characters.</div>
                  <div class="text-danger" v-if="!$v.company.hospitalAffiliatedName.required">This field is required.</div>
                </div>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row v-if="isReferralSourceVisible">
            <b-col lg="3">
              <b-form-group label="Referral Source">
                <b-form-input
                  :class="{ 'is-invalid' : $v.company.leadReferralSource.$error }"
                  type="text"
                  size="lg"
                  v-model="company.leadReferralSource"
                  @input="$v.company.leadReferralSource.$touch"/>
                <div class="text-danger" v-if="!$v.company.leadReferralSource.required && $v.company.leadReferralSource.$dirty">
                  This field is required.
                </div>
                <div class="text-danger" v-else-if="!$v.company.leadReferralSource.minLength || !$v.company.leadReferralSource.maxLength">
                  Must be between {{$v.company.leadReferralSource.$params.minLength.min}} and {{$v.company.leadReferralSource.$params.maxLength.max}} characters.
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-colxx md="3">
              <b-form-group label="Organization Currently Accredited?">
                <switches
                  v-model="company.leadCurrentlyAccredited"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-colxx>
            <b-colxx md="3">
              <b-form-group label="Current AO" v-if="company.leadCurrentlyAccredited">
                <multiselect
                  :class="{ 'multiselect-is-invalid': $v.company.leadCurrentAccreditationOrganizations.$error }"
                  v-model="company.leadCurrentAccreditationOrganizations"
                  :options="accreditationOrganizations"
                  track-by="accreditationOrganizationID"
                  label="displayName"
                  @input="$v.company.leadCurrentAccreditationOrganizations.$touch"
                  :multiple="true"
                  :close-on-select="false"
                ></multiselect>
                <div v-if="$v.company.leadCurrentAccreditationOrganizations.$error">
                  <div class="text-danger" v-if="!$v.company.leadCurrentAccreditationOrganizations.requiredIf">This field is required.</div>
                </div>
              </b-form-group>
            </b-colxx>
            <b-colxx md="3" v-if="isLeadAccreditationOrganizationOtherVisible">
              <b-form-group  label="Other">
                <b-form-input
                  type="text"
                  v-model="company.leadCurrentAccreditationOrganizationOther"
                  @input="$v.company.leadCurrentAccreditationOrganizationOther.$touch"
                  :state="$v.company.leadCurrentAccreditationOrganizationOther.$dirty ? !$v.company.leadCurrentAccreditationOrganizationOther.$error : null"
                />
                <div v-if="$v.company.leadCurrentAccreditationOrganizationOther.$error">
                  <div class="text-danger" v-if="!$v.company.leadCurrentAccreditationOrganizationOther.required">This field is required.</div>
                  <div class="text-danger" v-if="!$v.company.leadCurrentAccreditationOrganizationOther.maxLength || !$v.company.leadCurrentAccreditationOrganizationOther.minLength">Must be between {{$v.company.leadCurrentAccreditationOrganizationOther.$params.minLength.min}} and {{$v.company.leadCurrentAccreditationOrganizationOther.$params.maxLength.max}} characters.</div>
                </div>
              </b-form-group>
            </b-colxx>
            <b-colxx md="3">
              <b-form-group  v-if="company.leadCurrentlyAccredited" label="Current AO Expiration Date">
                <b-form-input
                  type="date"
                  v-model="aoExpirationDate"
                />
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="TIME">
                <switches
                  v-model="company.isTime"
                  theme="custom"
                  color="primary"
                  @input="TIMEChanged()"
                ></switches>
              </b-form-group>
            </b-colxx>
          </b-row>
          <achcu-leads
            v-if="isACHCUVisible"
            :company="company"
            :achcuProducts="achcuProducts">
          </achcu-leads>
          <b-row>
            <b-colxx xl="2" lg="4">
              <b-button
                block
                variant="danger"
                class="mt-4"
                size="lg"
                @click="cancelForm()"
                >{{ $t("forms.cancel") }}
              </b-button>
            </b-colxx>
            <b-colxx xl="2" lg="4">
              <b-button
                block
                type="submit"
                variant="success"
                class="mt-4"
                size="lg"
                >{{ $t("forms.save") }}
              </b-button>
            </b-colxx>
          </b-row>
        </b-form>
      </b-card>
    </b-colxx>
  </b-row>
</template>
<style lang="scss">

.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}

.ratioTag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
}
.ClosedLost {
  background-color: #FFFFFF;
  border: 2px solid #8f8f8f;
  color: #383838;
  width: 40px;
  height: 40px;
}
.ColdLead {
  background-color: #5DADE2;
  color: #FFFFFF;
}
.Lead {
  background-color: #52BE80;
  color: #FFFFFF;
}
.WarmLead {
  background-color: #EB984E;
  color: #FFFFFF;
}
.HotLead {
  background-color: #EC7063;
  color: #FFFFFF;
}
.ClosedWon {
  background-color: #FFFFFF;
  border: 2px solid #8f8f8f;
  color: #383838;
  width: 40px;
  height: 40px;
}
.subtitle {
  margin-bottom:10px;
}
.section-header {
  margin-top: 20px;
}
</style>
<script>
import AchcuLeads from '../../../components/Common/Company/AchcuLeads.vue'
import AffiliateMixin from '../../../mixins/AffiliateMixin.vue'
import AccreditationOrganizationMixin from '../../../mixins/AccreditationOrganizationMixin.vue'
import bus from '../../../main'
import CompanyMixin from '../../../mixins/CompanyMixin.vue'
import CompanyAddressMixin from '../../../mixins/CompanyAddressMixin.vue'
import confirmation from '../../../components/Common/Confirmation.vue'
import DirtyDataMixin from '../../../mixins/DirtyDataMixin.vue'
import NotificationMixin from '../../../mixins/NotificationMixin.vue'
import moment from 'moment/moment.js'
import Multiselect from 'vue-multiselect'
import ParentEntityPageTitle from '../../../components/Common/ParentEntityPageTitle.vue'
import ProgramMixin from '../../../mixins/ProgramMixin.vue'
import RoundRobinMixin from '../../../mixins/RoundRobinMixin.vue'
import Switches from 'vue-switches'
import TIMENotificationModal from '../../../components/Modals/TIMENotificationModal.vue'
import { mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'

const {
  required,
  maxLength,
  requiredIf,
  minLength,
  numeric,
  helpers
} = require('vuelidate/lib/validators')

const url = helpers.regex(
  'url',
  /^((http|ftp|https):\/\/)?(www\.)?([\w-]+\.)+(com\b|edu\b|biz\b|gov\b|in(?:t|fo)\b|mil\b|net\b|org\b|[a-z][a-z]\b)(\/[\w\- ./?]*)?$/
)

export default {
  components: {
    switches: Switches,
    multiselect: Multiselect,
    Confirmation: confirmation,
    PageTitle: ParentEntityPageTitle,
    TIMENotificationModal: TIMENotificationModal,
    AchcuLeads: AchcuLeads
  },
  props: ['companyID'],
  data () {
    return {
      title: 'Lead',
      activateTIMEModal: false,
      consultantOptions: []
    }
  },
  async created () {
    if (!this.companyID) {
      this.title = 'Add New Lead'
      this.company = {
        companyID: 0,
        notes: null,
        inquiryReceived: null,
        companyLegalName: '',
        dbaCompanyName: null,
        website: null,
        programsOfInterest: [],
        numberofLocations: null,
        leadTypeID: null,
        accreditationCompletedByID: null,
        hospitalAffiliated: null,
        hospitalAffiliatedName: null,
        leadCurrentlyAccredited: null,
        leadCurrentAccreditationOrganizations: [],
        leadCurrentAccreditationOrganizationOther: '',
        previousAccreditationExpiration: null,
        isTime: null,
        salesMemberID: null,
        leadDescription: null,
        businessLines: null,
        leadReferralType: null,
        leadReferralSource: '',
        leadOperatingState: null,
        leadTypeOther: '',
        leadReferralTypeOther: '',
        active: true,
        dateCreated: null,
        createdBy: null,
        dateLastModified: null,
        lastModifiedBy: null,
        inquiryAffiliateID: null,
        consultantsOther: '',
        achcU_OpportunityName: '',
        achcU_PrimaryCampaignSource: '',
        achcU_Type: '',
        achcU_InquiryReceivedDate: null,
        achcU_RegistrationDate: null,
        achcU_DeliveryDate: null,
        achcU_SurveyDate: null,
        ncalPilotParticipant: false
      }
    } else {
      this.title = 'Edit Lead'
      await this.getCompanyByID(this.companyID)
    }
    this.initDirtyDataWatcher('company')
    await this.getLeadTypes()
    await this.getTimeframes()
    await this.getBusinessLines()
    await this.getAllProgramsByType()
    await this.getAllSalesMembers()
    await this.getLeadDescriptions()
    await this.getLeadReferralTypes()
    await this.getAllActiveAccreditationOrganizations()
    await this.getUSStates()
    await this.getAllCertifiedConsultants()
    this.mapConsultants()
    await this.getAchcuProducts()

    bus.$on('saveCompany', (data) => {
      this.saveCompany(data)
    })

    bus.$on('showTIMECancelWarnMessage', () => {
      this.setWarningMessage('TIME Notification Canceled')
      window.scrollTo(0, 0)
    })
    window.scrollTo(0, 0)
  },
  mixins: [validationMixin, CompanyMixin, CompanyAddressMixin, AffiliateMixin, AccreditationOrganizationMixin, DirtyDataMixin, NotificationMixin, RoundRobinMixin, ProgramMixin],
  validations: {
    company: {
      companyLegalName: {
        maxLengthValue: maxLength(75),
        required
      },
      dbaCompanyName: {
        maxLengthValue: maxLength(75)
      },
      website: {
        maxLengthValue: maxLength(255),
        url
      },
      leadCurrentAccreditationOrganizations: {
        required: requiredIf(function () {
          return this.company.leadCurrentlyAccredited
        })
      },
      leadCurrentAccreditationOrganizationOther: {
        required: requiredIf(function () {
          if ([null, undefined].includes(this.company.leadCurrentAccreditationOrganizations)) {
            return false
          }
          return this.company.leadCurrentAccreditationOrganizations.find(el => el.accreditationOrganizationID === 11)
        }),
        minLength: minLength(2),
        maxLength: maxLength(100)
      },
      hospitalAffiliatedName: {
        maxLengthValue: maxLength(100),
        required: requiredIf(function () {
          if (this.company.hospitalAffiliated) {
            return true
          }
          return false
        })
      },
      leadReferralType: {
        required: false
      },
      leadReferralSource: {
        required: requiredIf(function () {
          if (this.company.leadReferralType === null || this.company.leadReferralType === undefined) {
            return false
          }
          return this.company.leadReferralType === 7
        }),
        minLength: minLength(2),
        maxLength: maxLength(100)
      },
      leadReferralTypeOther: {
        required: requiredIf(function () {
          if (this.company.leadReferralType === null || this.company.leadReferralType === undefined || this.company.leadReferralType === '') {
            return false
          }
          return this.company.leadReferralType.id === 28
        }),
        maxLength: maxLength(150)
      },
      leadTypeOther: {
        // leadType ID of 6 should be  'Other'.  LeadType is a table in Horizon.
        // If you're having issues make sure to check that the record in the database table with an ID of 6 matches up to 'Other'.
        // It might change during the next DB refresh/wipe.
        required: requiredIf(function () {
          if (this.company.leadTypeID === null || this.company.leadTypeID === undefined) {
            return false
          }
          return this.company.leadTypeID.id === 6
        }),
        maxLength: maxLength(150)
      },
      inquiryAffiliateID: {
        required: requiredIf(function () {
          if (this.company.leadReferralTypeID === null || this.company.leadReferralTypeID === undefined) {
            return false
          }
          return this.company.leadReferralType.id === 2
        })
      },
      consultantsOther: {
        required: requiredIf(function () {
          if (this.company.inquiryAffiliateID === null || this.company.inquiryAffiliateID === undefined) {
            return false
          }
          return (this.company.inquiryAffiliateID === -1 && this.company.leadReferralType && this.company.leadReferralType.id === 2)
        }),
        maxLength: maxLength(150)
      },
      numberofLocations: {
        numeric
      }
    }
  },
  beforeDestroy () {
    bus.$off('saveCompany')
    bus.$off('showTIMECancelWarnMessage')
  },
  methods: {
    ...mapMutations(['setPreserveMessageAfterPush']),
    TIMEChanged () {
      this.activateTIMEModal = this.company.isTime
    },
    onValidateFormSubmit () {
      this.$v.company.$touch()
      if (!this.$v.company.$error) {
        if (this.company.leadDescription !== null && this.company.leadDescription.leadDescriptionID !== null && this.company.leadDescription.leadDescriptionID !== undefined && (this.company.leadDescription.leadDescription === 'Closed Lost' || this.company.leadDescription.leadDescription === 'Closed NA')) {
          this.$refs.Confirmation.show({
            title: 'Save Company',
            message: `Are you sure you want to set the description to ${this.company.leadDescription.leadDescription}? Setting this description will also set this company record to inactive.`,
            okButton: 'Confirm'
          }).then((result) => {
            if (result) {
              this.handleTIMEModal()
            } else {
              this.setDangerMessage('Form must be filled out properly.')
              this.setFocus()
            }
          })
        } else if (this.company.active === false) {
          this.$refs.Confirmation.show({
            title: 'Save Company',
            message: 'Are you sure you want to deactivate this company?',
            okButton: 'Confirm'
          }).then((result) => {
            if (result) {
              this.handleTIMEModal()
            } else {
              this.setDangerMessage('Form must be filled out properly.')
              this.setFocus()
              this.company.active = true
            }
          })
        } else {
          // success case, no errors found.  send  time modal and attempt save company
          this.setFocus()
          this.handleTIMEModal()
        }
      } else {
        this.setDangerMessage('Form must be filled out properly.')
      }
    },
    async saveCompany (empData) {
      this.setFocus()
      if (this.company.companyID) {
        await this.updateCompany(this.company)
        if (this.statusCode === 200) {
          // We are saving the dirty changes, so we need to bypass the warning message about changes
          this.setDirtyData(false)
          await this.sendNotification(empData)
          await this.getCompanyByID(this.companyID)
        }
      } else {
        await this.addNewCompany(this.company)
        if (this.statusCode === 200) {
          // We are saving the dirty changes, so we need to bypass the warning message about changes
          this.setDirtyData(false)
          // By navigating to the edit version of this same page, we ensure the company gets put into context.
          this.setPreserveMessageAfterPush(true)

          await this.sendNotification(empData)
          if (this.statusCode === 200) {
            this.$router.push({ name: 'EditCompany', params: { companyID: this.company.companyID } })
          }
        }
      }
    },
    async sendNotification (empData) {
      await empData.empByRole.forEach(async e => {
        const notification = {
          notificationText: `You have a TIME Survey for Company ID: ${this.company.companyID}`,
          notificationTypeID: 7,
          notificationURL: this.$route.fullPath,
          employeeID: e.selectedEmployee.employeeID
        }

        await this.createNotification(notification)
      })
    },
    async handleTIMEModal () {
      if (this.activateTIMEModal) {
        await this.$refs.TIMENotificationModal.show({
          title: 'Send TIME Notifications',
          companyID: this.company.companyID

        })
        return
      }
      this.saveCompany()
    },
    cancelForm () {
      this.$router.push({ name: 'leads' })
    },
    setFocus () {
      setTimeout(() => {
        this.$refs.companylegalname.$el.focus()
      }, 1000)
    },
    mapConsultants () {
      this.consultantOptions = this.consultants.map((c) => {
        return {
          text: c.companyName,
          value: c.affiliateID
        }
      })
      // Pierson 9/15/21 - I added the push in here instead of in the API
      // so that it would keep the API agnostic and not mess up other GetAll calls
      this.consultantOptions.push({
        value: -1,
        text: 'Other'
      })
    }
  },
  computed: {
    isConsultantVisible: {
      get: function () {
        const selectedValue = this.company.leadReferralType && this.company.leadReferralType.id
        return selectedValue === 2
      }
    },
    isConsultantsOtherVisible: {
      get: function () {
        return (this.company.inquiryAffiliateID === -1 && this.company.leadReferralType && this.company.leadReferralType.id === 2)
      }
    },
    inquiryReceivedDate: {
    // getter
      get: function () {
        if (this.company.inquiryReceived === null) {
          return null
        }
        return moment(this.company.inquiryReceived).format('yyyy-MM-DD')
      },
      // setter
      set: function (newValue) {
        this.company.inquiryReceived = newValue
      }
    },
    aoExpirationDate: {
    // getter
      get: function () {
        if (this.company.previousAccreditationExpiration === null) {
          return null
        }
        return moment(this.company.previousAccreditationExpiration).format('yyyy-MM-DD')
      },
      // setter
      set: function (newValue) {
        this.company.previousAccreditationExpiration = newValue
      }
    },
    leadDescriptionBorder: {
      get: function () {
        if (this.company.leadDescription === null || this.company.leadDescription === undefined) {
          return ''
        }
        if (this.company.leadDescription.color === undefined || this.company.leadDescription.color === null) {
          return null
        }
        return 'border: 2px solid ' + this.company.leadDescription.color + ' !important;'
      }
    },
    closeRatio: {
      get: function () {
        if (this.company.leadDescription !== null) {
          return this.company.leadDescription.leadDescription === 'Closed NA' ? 'NA' : `${this.company.leadDescription.closeRatio * 100}%`
        }
        return '0%'
      }
    },
    closeRatioTagClass: {
      get: function () {
        if (this.company.leadDescription !== null) {
          return 'ratioTag ' + this.company.leadDescription.leadDescription.replace(/\s/g, '')
        }
        return 'ratioTag ClosedLost'
      }
    },
    isReferralSourceVisible: {
      get: function () {
        if (this.company.leadReferralType === null || this.company.leadReferralType === undefined) {
          return false
        }

        return this.company.leadReferralType.id === 7
      }
    },
    isLeadTypeOtherVisible: {
      get: function () {
        if (this.company.leadTypeID === null || this.company.leadTypeID === undefined) {
          return false
        }

        return this.company.leadTypeID.id === 6
      }
    },
    isLeadReferralTypeOtherVisible: {
      get: function () {
        if (this.company.leadReferralType === null || this.company.leadReferralType === undefined) {
          return false
        }
        // TZ - LeadReferralType with an ID of 28 is 'Other' as of today 05/04/22.  The ID had been changed after the most recent DB reload/update.
        // If you're having issues make sure to check that the record in the database table with an ID of 28 matches up to 'Other'.
        return this.company.leadReferralType.id === 28
      }
    },
    pageTitle: {
      get: function () {
        if (this.companyID) {
          return 'Edit Lead'
        } else {
          return 'New Lead'
        }
      }
    },
    timeBorderClass: {
      get: function () {
        if (this.company.isTime) {
          return 'TIME-border'
        } else {
          return null
        }
      }
    },
    isACHCUVisible: {
      get: function () {
        if (this.company.businessLines === null || this.company.businessLines === undefined) {
          return false
        }

        return this.company.businessLines.find(bl => bl.id === 2)
      }
    },
    isLeadAccreditationOrganizationOtherVisible () {
      if (!this.company.leadCurrentlyAccredited || [null, undefined].includes(this.company.leadCurrentAccreditationOrganizations)) {
        return false
      }
      return this.company.leadCurrentAccreditationOrganizations.find(el => el.accreditationOrganizationID === 11)
    }
  }
}
</script>
<style scoped>
.TIME-border {
  border-style: solid;
  border-color: red;
  border-width: 1px;
  box-shadow: rgb(255, 0, 0) 0px 0px 8px;
}
.TIME-top-right {
  color:red;
  font-size: 24px;
  margin-right: 0.5rem;
  font-weight: 700;
}
</style>
