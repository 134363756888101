<template>
  <div v-if="leadsPermission">
    <h3 class="subtitle section-header">ACHCU Leads</h3>

    <b-row>
      <b-colxx lg="3">
        <b-form-group label="Opportunity Name">
          <b-form-input v-model="company.achcU_OpportunityName"></b-form-input>
        </b-form-group>
      </b-colxx>

      <b-colxx lg="3">
        <b-form-group label="Primary Campaign Source">
          <b-form-input
            v-model="company.achcU_PrimaryCampaignSource"
          ></b-form-input>
        </b-form-group>
      </b-colxx>

      <b-colxx lg="2">
        <b-form-group label="Type">
          <b-form-input v-model="company.achcU_Type"></b-form-input>
        </b-form-group>
      </b-colxx>
    </b-row>

    <b-row>
      <b-colxx lg="3">
        <b-form-group label="Inquiry Received Date">
          <b-form-input type="date" v-model="achcuInquiryReceivedDate" />
        </b-form-group>
      </b-colxx>

      <b-colxx lg="3">
        <b-form-group label="Registration Date">
          <b-form-input type="date" v-model="achcuRegistrationDate" />
        </b-form-group>
      </b-colxx>

      <b-colxx lg="3">
        <b-form-group label="Delivery Date">
          <b-form-input type="date" v-model="achcuDeliveryDate" />
        </b-form-group>
      </b-colxx>
    </b-row>
    <b-row>
      <b-colxx lg="3">
        <b-form-group label="Survey Date">
          <b-form-input type="date" v-model="achcuSurveyDate" />
        </b-form-group>
      </b-colxx>
      <b-colxx>
        <b-form-group label="Inquiry Recieved Date to Close Date">
          <b-form-input
            type="text"
            :value="achcuCreatedToClosedNumberOfDays"
            disabled
          />
        </b-form-group>
      </b-colxx>
    </b-row>
    <b-row>
      <b-colxx lg="6">
        <b-form-group label="Products">
          <multiselect
            v-model="company.achcuProducts"
            :options="this.achcuProducts"
            track-by="achcuProductID"
            label="productName"
            :close-on-select="false"
            :multiple="true"
            placeholder="Select Products"
          ></multiselect>
        </b-form-group>
      </b-colxx>
    </b-row>
  </div>
</template>
<script>
import Multiselect from '../../../../node_modules/vue-multiselect/src/Multiselect.vue'
import moment from '../../../../node_modules/moment/moment'

export default {
  props: {
    company: { type: Object, default: undefined },
    achcuProducts: { type: Array, default: null }
  },
  components: {
    multiselect: Multiselect

  },
  computed: {
    achcuInquiryReceivedDate: {
      get: function () {
        if (this.company.achcU_InquiryReceivedDate === null) {
          return null
        }
        return moment(this.company.achcU_InquiryReceivedDate).format('yyyy-MM-DD')
      },
      // setter
      set: function (newValue) {
        this.company.achcU_InquiryReceivedDate = newValue
      }
    },
    achcuRegistrationDate: {
      get: function () {
        if (this.company.achcU_RegistrationDate === null) {
          return null
        }
        return moment(this.company.achcU_RegistrationDate).format('yyyy-MM-DD')
      },
      // setter
      set: function (newValue) {
        this.company.achcU_RegistrationDate = newValue
      }
    },
    achcuSurveyDate: {
      get: function () {
        if (this.company.achcU_SurveyDate === null) {
          return null
        }
        return moment(this.company.achcU_SurveyDate).format('yyyy-MM-DD')
      },
      // setter
      set: function (newValue) {
        this.company.achcU_SurveyDate = newValue
      }
    },
    achcuDeliveryDate: {
      get: function () {
        if (this.company.achcU_DeliveryDate === null) {
          return null
        }
        return moment(this.company.achcU_DeliveryDate).format('yyyy-MM-DD')
      },
      // setter
      set: function (newValue) {
        this.company.achcU_DeliveryDate = newValue
      }
    },
    achcuCreatedToClosedNumberOfDays: {
      get: function () {
        // make sure that we have values for both create date and close date, if not, return N/A
        if (this.company.achcU_InquiryReceivedDate && this.company.leadCloseDate) {
          var receivedDate = moment(this.company.achcU_InquiryReceivedDate)
          var closeDate = moment(this.company.leadCloseDate)
          // check to make sure the created(received) date is before the close date
          var isBefore = receivedDate.isBefore(closeDate)
          var difference = closeDate.diff(receivedDate, 'days')
          if (isBefore) {
            return difference + ' days'
          } else {
            return 'N/A'
          }
        } else {
          return 'N/A'
        }
      }
    },
    leadsPermission () {
      var permissions = JSON.parse(localStorage.getItem('userPermissions'))
      return permissions.some(p => p.permissionName === 'Leads Dashboard')
    }
  }
}
</script>
