<template>
  <b-modal
    id="modalbasic"
    v-b-modal.modal-center
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    @cancel="close()"
    @close="close()"
    :no-close-on-backdrop="true"
  >
    <Messages />
    <b-row>
      <b-col>
        <div v-if="!isLoading">
          <b-form v-for="(employeeRole, index) in employeesByRole" :key="employeeRole.roleId">
            <b-col>
              <b-form-group
                :label="employeeRole.roleName"
                :label-for="employeeRole.roleName"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <multiselect
                  :class="{ 'multiselect-is-invalid' : $v.employeesByRole.$each[index].selectedEmployee.$error }"
                  :id="employeeRole.roleName"
                  :options="employeeRole.employees"
                  v-model="employeesByRole[index].selectedEmployee"
                  :multiple="false"
                  :close-on-select="true"
                  placeholder="Select Employee"
                  label="name"
                  track-by="employeeID"
                  @input="$v.employeesByRole.$each[index].$touch"
                >
                </multiselect>
                <div class="text-danger" v-if="!$v.employeesByRole.$each[index].selectedEmployee.required">This field is required.</div>
              </b-form-group>
            </b-col>
          </b-form>
        </div>
        <div class="flex-col flex-center mb-5 mt-5" style="width: 100%" v-if="isLoading">
          <b-spinner class="m0-auto primary-color"></b-spinner>
        </div>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button v-if="!$v.$invalid && !isLoading"
        variant="success"
        class="mr-1"
        @click="sendEmployeeNotifications()">
        Send Notifications
      </b-button>
      <b-button
        variant="outline-danger"
        class="mr-1"
        @click="(cancel())">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
  .is-invalid {
    border: 1px solid #dc3545 !important;
  }
  .multiselect-is-invalid ::v-deep .multiselect__tags {
    border: 1px solid #dc3545 !important;
  }
  .alignWithLabel{
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
  }
</style>

<script>
import { validationMixin } from 'vuelidate'
import Multiselect from 'vue-multiselect'
import { mapMutations } from 'vuex'
import ModalMessages from '../../containers/navs/ModalMessages.vue'
import NotificationMixin from '../../mixins/NotificationMixin.vue'
import EmployeeMixin from '../../mixins/EmployeeMixin.vue'
import bus from '../../main'

const {
  required
} = require('vuelidate/lib/validators')

export default {
  components: {
    multiselect: Multiselect,
    Messages: ModalMessages
  },

  data () {
    return {
      title: undefined,
      isVisible: false,
      companyID: 0,
      // This should be changed from being hardcoded in the future. (These roles are not yet finalized, so hardcoding for now)
      roles: [
        { id: 19, displayName: 'Sales Manager' },
        { id: 20, displayName: 'Program Owner' },
        { id: 21, displayName: 'Customer Experience Manager' },
        { id: 22, displayName: 'Scheduling Customer Experience Manager' }
      ],
      isLoading: false
    }
  },
  created () {
  },
  mixins: [validationMixin, NotificationMixin, EmployeeMixin],
  validations: {
    employeesByRole: {
      $each: {
        selectedEmployee: {
          required
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),

    async show (opts = {}) {
      this.title = opts.title
      this.companyID = opts.companyID
      this.isVisible = true
      await this.loadEmployeesByRole()
    },

    close () {
      this.employeesByRole.forEach(e => { e.selectedEmployee = undefined })
      this.roles = []
      this.isVisible = false
      bus.$emit('showTIMECancelWarnMessage')
    },
    async loadEmployeesByRole () {
      this.isLoading = true

      for (const role of this.roles) {
        await this.getEmployeesByRole(role.id, role.displayName)
      }

      this.isLoading = false
    },
    async sendEmployeeNotifications () {
      bus.$emit('saveCompany', { empByRole: _.cloneDeep(this.employeesByRole) })
      this.close()
    },
    cancel () {
      this.close()
    }
  }
}
</script>
